<template>

    <table class="table">
        <thead>
            <tr>
                <th>JR #</th>
                <th>Type</th>
                <th>Description</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="jobs.length < 1">
                <td class="text-center" colspan="4">No jobs found.</td>
            </tr>
            <tr v-for="job in jobs" :key="'job-' + job.id">
                <td>{{job.id}}</td>
                <td>{{job.type}}</td>
                <td>{{job.description}}</td>
                <td>
                    <button :disabled="job.downloading" class="btn btn-sm btn-primary" @click="downloadJobSheet(job)">Download Jobsheet</button>
                    <button :disabled="job.deleting" class="btn btn-sm btn-danger mx-2" @click="removeJob(job)">Remove</button>
                </td>
            </tr>
        </tbody>
    </table>

</template>

<script>
import axios from 'axios';
import saveAs from 'file-saver';
export default {
    name: 'CompletedJobs',
    data(){
        return {
            jobs: []
        }
    },
    mounted(){
        this.getJobs();
    },
    methods: {
        getJobs(){
            axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/completed/queue`)
            .then(response => {
                response.data.completed_jobs.forEach((job) => {
                    job.deleting = false;
                    job.downloading = false;
                })
                this.jobs = response.data.completed_jobs;
            })
            .catch(error => {
                this.$error("Failed to load jobs", error);
            })
        },
        downloadJobSheet(job){
            job.downloading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${job.id}/jobsheet`, {
                responseType: "blob"
            })
            .then(response => {
                saveAs(response.data, `Job Report #${job.id}.pdf`);
            })
            .catch(error => {
                this.$error("Failed to generate report", error);
            })
            .finally(() => {
                job.downloading = false;
            });
        },
        removeJob(job){
            job.deleting = true;
            axios.delete(`${process.env.VUE_APP_API_URL}/v1/jobs/completed/queue/${job.id}`)
            .then(() => {
                this.$success("Job removed from queue");
                this.getJobs();
            })
            .catch(error => {
                this.$error("Failed to remove job", error);
            })
        }
    }
}
</script>